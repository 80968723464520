import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/-zTgkt6s_a0">
    <SEO title="The Disciples Actions and Reactions - The Week That Changed the World" />
  </Layout>
)

export default SermonPost
